<template>
  <div class="bitts-checkbox">
    <!-- controlled -->
    <checkbox
      v-if="checked"
      :class="{
        'bitts-checkbox-with-label': !!label,
      }"
      :checked="checked"
      :disabled="disabled"
      @change="onChange"
    >
      <template v-if="label">
        <span class="bitts-checkbox-label">
          {{ label }}
        </span>
      </template>
      <slot class="bitts-checkbox-label" name="label" />
    </checkbox>
    <!-- uncontrolled -->
    <checkbox
      v-else
      :class="{
        'bitts-checkbox-with-label': !!label,
      }"
      :default-checked="defaultChecked"
      :indeterminate="indeterminate"
      :disabled="disabled"
      @change="onChange"
    >
      <template v-if="label">
        <span class="bitts-checkbox-label">
          {{ label }}
        </span>
      </template>
      <slot class="bitts-checkbox-label" name="label" />
    </checkbox>
  </div>
</template>

<script setup lang="ts">
import { Checkbox } from 'ant-design-vue';

interface Props {
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
  label?: string;
}

withDefaults(defineProps<Props>(), {
  checked: false,
  defaultChecked: false,
  disabled: false,
  indeterminate: false,
  label: '',
});

const emit = defineEmits<{
  input: [value: boolean];
}>();
function onChange(e: { target: { checked: boolean } }) {
  emit('input', e.target.checked);
}
</script>

<style lang="pcss">
.bitts-checkbox {
  .ant-checkbox {
    @apply top-0;
  }

  .bitts-checkbox-with-label {
    .ant-checkbox {
      @apply leading-none mb-2;
    }
  }
  .bitts-checkbox-label {
    @apply flex items-center;
  }
  .ant-checkbox-wrapper {
    @apply font-normal flex items-center;
    font-family: inherit;
  }

  .ant-checkbox-wrapper:hover {
    @apply text-secondary-background-medium;
  }

  .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-checked:not(.ant-checkbox-disabled)
    .ant-checkbox-inner {
    @apply bg-secondary-background-medium;
  }

  .ant-checkbox-checked::after {
    @apply border-none;
  }

  .ant-checkbox-inner {
    @apply border transition-none border-neutral-border rounded-3;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    @apply bg-secondary-background-medium border-secondary-background-medium border-2;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    @apply w-[4px] h-[9px] border-[1.8px];
    top: 50%;
    left: 15%;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-inner:hover {
    @apply border-2 border-secondary-background-medium;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    @apply border-neutral-border-focus border-2;
  }

  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      @apply cursor-not-allowed;
      @apply bg-neutral-background-disabled;
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner::after {
        @apply border-neutral-border-focus;
      }
      .ant-checkbox-inner {
        @apply border-2 rounded-3;
        @apply border-neutral-background-disabled !important;
      }
    }
    &:not(.ant-checkbox-checked) {
      .ant-checkbox-inner {
        @apply bg-neutral-background-disabled;
        @apply border-neutral-background-disabled !important;
      }
    }
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      &:after {
        @apply bg-secondary-background-medium;
      }
      &:hover {
        @apply border-secondary-background-medium;
      }
      &:focus {
        @apply border-neutral-border-focus;
      }
    }
    &.ant-checkbox-disabled {
      @apply cursor-not-allowed bg-neutral-background-disabled;
      .ant-checkbox-inner {
        &:after {
          @apply bg-neutral-accent;
        }
      }
    }
    @apply rounded-4;
  }

  span {
    @apply text-neutral-text-strong;
  }
}
</style>
